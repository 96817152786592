/*-------- 17. Product details style ---------*/

.product-details-img-left {
    margin-right: -30px;
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    img {
        width: 100%;
    }
}

.pro-details-content-modify {
    margin-left: 70px;
    @media #{$lg-layout} {
        margin-left: 50px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-top: 20px;
    }
}

.dec-review-topbar {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
    padding: 11px 0 14px;
    a {
        font-size: 14px;
        color: #bababa;
        font-weight: 500;
        margin: 0 49px;
        @media #{$xs-layout} {
            margin: 0 10px;
        }
        @media #{$sm-layout} {
            margin: 0 30px;
        }
        &.active {
            color: #333333;
        }
    }
}

.dec-review-bottom {
    .description-wrap {
        p {
            line-height: 26px;
            font-size: 16px;
            margin: 0;
            color: #666666;
        }
    }
    .specification-wrap {
        table {
            width: 100%;
            tbody {
                border: 1px solid #f0f0f0;
                width: 100%;
                tr {
                    border-bottom: 1px solid #f0f0f0;
                    td {
                        border-right: 1px solid #f0f0f0;
                        font-size: 14px;
                        color: #333333;
                        padding: 17px 70px;
                        @media #{$xs-layout} {
                            padding: 17px 15px;
                        }
                    }
                    td.width1 {
                        width: 300px;
                        @media #{$xs-layout} {
                            width: 150px
                        }
                    }
                }
            }
        }
    }
    .dec-review-wrap {
        .dec-review-img-wrap {
            display: flex;
            align-items: center;
            .dec-review-img {
                flex: 0 0 70px;
                margin-right: 30px;
                img {
                    width: 100%;
                }
            }
            .dec-client-name {
                h4 {
                    margin: 0 0 2px;
                    font-size: 18px;
                    color: #333333;
                }
                .dec-client-rating {
                    i {
                        font-size: 16px;
                        color: $theme-color;
                        opacity: .4;
                        margin: 0 0px 0 0;
                    }
                }
            }
        }
        .dec-review-content {
            margin-left: 52px;
            @media #{$md-layout} {
                margin-left: 0px;
            }
            @media #{$xs-layout} {
                margin-left: 0px;
                margin-top: 20px;
            }
            p {
                color: #666666;
                line-height: 26px;
                margin: 0;
            }
            .review-content-bottom {
                display: flex;
                justify-content: space-between;
                margin: 7px 0 0;
                .review-like {
                    span {
                        font-size: 13px;
                        font-weight: 300;
                        color: $theme-color;
                        display: block;
                        line-height: 1;
                        i {
                            font-size: 16px;
                            margin: 0 7px 0 0;
                            position: relative;
                            top: 2px;
                        }
                    }
                }
                .review-date {
                    span {
                        font-size: 13px;
                        font-weight: 300;
                        color: $theme-color;
                        display: block;
                        line-height: 1;
                    }
                }
            }
        }
        @media #{$xs-layout} {
            &.mb-50 {
                margin-bottom: 30px;
            }
        }
    }
}





.single-pro-details2-wrap {
    overflow: hidden;
    .single-pro-details2-content {
        padding: 20px 118px 20px 100px;
        @media #{$lg-layout} {
            padding: 20px 60px 20px 60px;
        }
        @media #{$md-layout} {
            padding: 20px 30px 20px 30px;
        }
        @media #{$xs-layout} {
            padding: 0px 0px 30px 0px;
        }
        @media #{$sm-layout} {
            padding: 0px 15px 0px 15px;
        }
        h3 {
            font-weight: bold;
            font-size: 28px;
            color: #333333;
            line-height: 28px;
            margin: 0 0 30px;
            @media #{$lg-layout} {
                font-size: 26px;
            }
            @media #{$md-layout} {
                font-size: 22px;
            }
            @media #{$xs-layout} {
                font-size: 21px;
                margin: 0 0 10px;
                line-height: 20px;
            }
            @media #{$sm-layout} {
                font-size: 16px;
                line-height: 24px;
            }
        }
        p {
            font-size: 16px;
            color: #333333;
            line-height: 25px;
            margin: 0 0 0px;
        }
    }
    .single-pro-details2-img {
        overflow: hidden;
        transition: all .5s ease 0s;
        img {
            width: 100%;
            transform: scale(1);
            transition: all .5s ease 0s;
        }
    }
    &:hover .single-pro-details2-img img {
        transform: scale(1.2);
    }
}


.product-details-img {
	position: relative;
    .zoompro-span {
        position: relative;
        .zoompro {
            width: 100%;
        }
        > span {
            background-color: $theme-color;
            color: #fff;
            left: 20px;
            line-height: 1;
            padding: 4px 10px 5px;
            position: absolute;
            top: 20px;
            z-index: 99;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
        }
    }
    .product-dec-slider {
        padding: 0 50px 0 70px;
        @media #{$md-layout} {
            padding: 0 0px 0 15px;
        }
        @media #{$xs-layout} {
            padding: 0 30px 0 45px;
            margin-bottom: 20px;
        }
        .product-dec-icon {
            color: #1f2226;
            display: inline-block;
            font-size: 13px;
            left: 30px;
            opacity: 1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            z-index: 99;
            width: 30px;
            height: 30px;
            line-height: 28px;
            border-radius: 100%;
            border: 1px solid #e1e2e2;
            text-align: center;
            @media #{$md-layout} {
                left: 0px;
            }
            @media #{$xs-layout} {
                left: 0px;
            }
            &:hover {
                background-color: $theme-color;
                border: 1px solid $theme-color;
                color: $white;
            }
            &.product-dec-next {
                left: auto;
                right: 30px;
                @media #{$md-layout} {
                    right: 0px;
                }
                @media #{$xs-layout} {
                    right: 0px;
                }
            }
        }
    }
    .product-dec-slider-2 {
        display: inline-block;
        padding: 0;
        width: 20%;
        margin: 12px 0 0px;
        float: left;
        @media #{$xs-layout} {
            width: 30%;
        }
        @media #{$sm-layout} {
            width: 20%;
        }
        .slick-slide {
            margin-bottom: 3px;
            margin-top: 3px;
            @media #{$lg-layout} {
                margin-bottom: 5px;
            }
            @media #{$md-layout} {
                margin-bottom: 10px;
            }
            img {
                width: 100%;
            }
        }
        .product-dec-icon {
            cursor: pointer;
            display: inline-block;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            z-index: 99;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 100%;
            border: 1px solid #e1e2e2;
            text-align: center;
            font-size: 13px;
            color: #1f2226;
            &:hover {
                background-color: $theme-color;
                border: 1px solid $theme-color;
                color: $white;
            }
            &.product-dec-prev {
                top: -13px;
            }
            &.product-dec-next {
                bottom: -7px;
            }
        }
    }
    .product-zoom-right {
        display: inline-block;
        position: relative;
        width: 80%;
        float: left;
        @media #{$xs-layout} {
            width: 70%;
        }
        @media #{$sm-layout} {
            width: 80%;
        }
    }
}


.sidebar-active {
    @media #{$xs-layout}{
        & .product-details-content {
            margin-top: 0;
        }
    }
    &.col-lg-6 {
        padding-left: 15px;
        padding-right: 15px;
        &.is-affixed {
            padding-left: 0px;
            padding-right: 0px;
            & .inner-wrapper-sticky {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.box-slider-active {
    &.owl-carousel {
        .owl-nav button {
            color: #1f2226;
            display: inline-block;
            font-size: 18px;
            left: 10px;
            opacity: 1;
            visibility: visible;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s ease 0s;
            border: 1px solid #e1e2e2;
            background-color: $white;
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 100%;
            @media #{$lg-layout} {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 15px;
            }
            @media #{$md-layout} {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 15px;
            }
            @media #{$xs-layout} {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 15px;
            }
            &:hover {
                background-color: $theme-color;
                color: $white;
                border: 1px solid $theme-color;
            }
            &.owl-next {
                left: auto;
                right: 10px;
            }
        }
    }
}










