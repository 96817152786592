/*-------- 10. Instagram style ---------*/

.instafeed-style {
	display: flex;
}
.instagram-item {
    overflow: hidden;
    a {
        img {
            width: 100%;
            transform: scale(1);
            transition: all .5s ease 0s;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}



























